<template>
  <div>
    <v-snackbar v-model="snackbar" color="#3CB043" right :timeout="3000">
      <v-layout wrap>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="snackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout wrap justify-center>
          <v-flex xs12 sm6>
            <v-img src="./../../assets/signUp.png">
              <v-layout wrap justify-center align-center fill-height pb-16>
                <v-flex xs12 pb-16 text-left>
                  <v-layout wrap justify-center>
                    <v-flex xs12 sm6>
                      <span
                        style="
                          font-family: poppinsbold;
                          color: white;
                          font-size: 30px;
                        "
                        >Your Education Journey Starts Here</span
                      >
                    </v-flex>
                  </v-layout>
                  <v-layout wrap justify-center pt-6>
                    <v-flex xs12 sm6>
                      <span
                        style="
                          font-family: poppinsregular;
                          color: white;
                          font-size: 16px;
                        "
                        >Embrace a
                        future filled with endless possibilities as you join our
                        educational community</span
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-img>
          </v-flex>
          <v-flex xs12 sm6 align-self-center>
            <v-layout wrap justify-center>
              <v-flex xs12 sm9>
                <v-card outlined>
                  <v-form ref="form">
                  <v-layout wrap justify-center py-10>
                    <v-flex xs10>
                      <v-layout wrap>
                        <v-flex xs12>
                          <span
                            style="
                              font-family: poppinsregular;
                              color: #5a5a5a;
                              font-size: 16px;
                            "
                            >Welcome !</span
                          >
                        </v-flex>
                        <v-flex xs12>
                          <span
                            style="
                              font-family: poppinsbold;
                              color: #5a5a5a;
                              font-size: 20px;
                            "
                            >Register your account</span
                          >
                        </v-flex>
                        <v-flex xs12 py-2>
                          <span
                            style="
                              font-family: poppinsregular;
                              color: #000000;
                              font-size: 14px;
                            "
                            >Enter your details below</span
                          >
                        </v-flex>
                      </v-layout>

                      <v-layout wrap>
                        <v-flex xs12 text-left>
                          <span
                            style="
                              font-family: opensansregular;
                              font-size: 14px;
                            "
                            >Account type</span
                          >
                          <v-select
                            outlined
                            dense
                            :hide-details="true"
                            v-model="userType"
                            :items="items"
                            item-text="name"
                            item-value="value"
                          >
                          </v-select>
                        </v-flex>
                        <v-flex xs12 text-left py-2>
                          <span
                            style="
                              font-family: opensansregular;
                              font-size: 14px;
                            "
                            >Full name</span
                          >
                          <v-text-field
                            outlined
                            dense
                            v-model="name"
                            :hide-details="true"
                          >
                          </v-text-field>
                        </v-flex>
                        <v-flex xs12 text-left>
                          <span
                            style="
                              font-family: opensansregular;
                              font-size: 14px;
                            "
                            >Phone</span
                          >
                          <v-layout wrap>
                            <v-flex xs2 sm2 pt-1>
                              <vue-country-code
                                @onSelect="onSelect"
                                :preferredCountries="['vn', 'us', 'gb']"
                              >
                              </vue-country-code
                            ></v-flex>
                            <v-flex xs10 sm10>
                              <v-text-field
                                ref="phone"
                                color="#717171"
                                placeholder="Mobile number"
                                outlined
                                dense
                                type="number"
                                v-model="phone"
                              >
                                <template v-slot:prepend-inner>
                                  <v-layout justify-center>
                                    <v-flex pt-1 class="align-self-center">
                                      <span
                                        style="
                                          font-family: poppinsregular;
                                          font-size: 15px;
                                          color: #000;
                                        "
                                        >{{ formattedValue }}</span
                                      >
                                    </v-flex>
                                    <v-flex pt-1 pl-2 xs1>
                                      <v-divider vertical></v-divider>
                                    </v-flex>
                                  </v-layout>
                                </template>
                              </v-text-field>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 sm6 text-left pr-1>
                          <span
                            style="
                              font-family: opensansregular;
                              font-size: 14px;
                            "
                            >Password</span
                          >
                          <v-text-field
                            :rules="[rules.required, rules.min]"
                            v-model="password"
                            type="password"
                            outlined
                            dense
                          >
                          </v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 text-left pl-1>
                          <span
                            style="
                              font-family: opensansregular;
                              font-size: 14px;
                            "
                            >Confirm password</span
                          >
                          <v-text-field
                            outlined
                            dense
                            type="password"
                            :rules="[rules.required, rules.min, rules.check]"
                            v-model="confirmPassword"
                          >
                          </v-text-field>
                        </v-flex>
                        <v-layout
                          wrap
                          justify-start
                          v-if="userType == 'User'"
                        >
                          <v-flex text-left>
                            <span
                              style="
                                font-family: opensansregular;
                                font-size: 14px;
                              "
                              >Gender</span
                            >
                          </v-flex>
                          <v-flex  style="
                                font-family: opensansregular;
                                font-size: 14px;
                              ">
                            <v-radio-group v-model="gender" mandatory>
                              <v-layout wrap>
                                <v-flex>
                                  <v-radio small label="Male" value="Male"></v-radio>
                                </v-flex>
                                <v-flex>
                                  <v-radio
                                    label="Female"
                                    value="Female"
                                  ></v-radio>
                                </v-flex>
                              </v-layout>
                            </v-radio-group>
                          </v-flex>
                        </v-layout>
                        <v-flex xs12 text-left>
                          <span
                            style="
                              font-family: opensansregular;
                              font-size: 14px;
                            "
                            >Email</span
                          >
                          <v-text-field
                            outlined
                            dense
                            v-model="email"
                            :rules="emailRules"
                            @input="checkEmail"
                            :hide-details="true"
                          >
                            <template
                              v-slot:append
                              v-if="message.status == true"
                            >
                              <v-layout wrap justify-center>
                                <v-flex xs12 sm12 md12>
                                  <v-btn text color="#3CB043" @click="verifyEmail()">
                                    <span
                                      style="
                                        font-family: opensansregular;
                                        text-transform: none;
                                        color: #3cb043;
                                      "
                                      >Verify</span
                                    >
                                  </v-btn>
                                </v-flex>
                              </v-layout>
                            </template>
                          </v-text-field>
                          <span v-if="message.status == false"  style="
                                font-family: poppinsregular;
                                color: #3d3d3d;
                                font-size: 13px;
                              "
                            >Email already exist</span
                          >
                        </v-flex>
                        <v-layout wrap py-5
                        v-if="verify.status==true">
                          <v-flex xs12>
                            <span style="font-family: poppinsbold;">Please check your email for the verification code</span>
                          </v-flex>
                        </v-layout>
                        <v-layout wrap justify-center pt-2>
                          <v-flex xs12 sm10 md7 text-center
                            ><v-otp-input :dense="true" length="4" v-model="otp"></v-otp-input
                          ></v-flex>
                        </v-layout>
                        <v-layout wrap justify-center>
                          <v-flex xs12 sm10 md7 text-center
                            ><span
                              style="
                                font-family: poppinsregular;
                                color: #3d3d3d;
                                font-size: 13px;
                                text-decoration: underline;
                                cursor: pointer;
                              "
                              @click="verifyEmail()"
                              > Resend OTP ? </span
                            ></v-flex
                          >
                        </v-layout>
                        <v-flex xs12 py-5>
                          <v-btn color="#3CB043" block @click="signUp()"
                            ><span
                              style="
                                font-family: poppinsregular;
                                color: #fafafa;
                                text-transform: none;
                              "
                            >
                              Register</span
                            ></v-btn
                          >
                        </v-flex>
                        <v-flex xs12>
                          <span
                            style="
                              font-family: opensansregular;
                              font-size: 14px;
                              color: #000000;
                            "
                            >Already have an account
                            <span style="color: #3cb043"
                              ><router-link
                                style="text-decoration: none; color: #3cb043"
                                :to="'/loginForm'"
                                >Login</router-link
                              ></span
                            ></span
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  </v-form>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import store from "../../store";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      items: [{name : "Institution", value:"Institution"}, {name:"Student", value:"User"}],
      yourValue: "",
      value: "",
      phone: "",
      gender: "",
      userType: "",
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      showPassword: false,
      message: "",
      otp: "",
      verify:"",
      snackbar: false,
      msg: null,
      rules: {
        required: (value) => !!value || "Required.",
        min: (value) => value.length >= 8 || "Min 8 characters",
        pincode: (value) => value.length == 6 || "Please provide valid pincode",
        otp: (value) => value.length == 4 || "Please provide valid otp",
        check: (confirmation) =>
          confirmation === this.password || "Passwords do not match",
      },

      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail must be valid",
      ],
      phnoRules: [
        (v) => !!v || "Phone is required",
        (v) =>
          /[6-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]/.test(v) ||
          "Phone is invalid",
      ],
    };
  },
  computed: {
    formattedValue() {
      return "+" + this.value;
    },
  },
  methods: {
    onSelect({ name, iso2, dialCode }) {
      console.log(name, iso2, dialCode);
      this.value = dialCode;
    },
    checkEmail() {
      axios({
        url: "/user/check/email",
        method: "GET",
        params: {
          email: this.email,
        },
      })
        .then((response) => {
          this.message = response.data;
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    verifyEmail(){
      axios({
        url: "/user/verify/email",
        method: "POST",
        data: {
          email: this.email,
        },
      })
        .then((response) => {
          this.verify = response.data;
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    signUp() {
      if (this.$refs.form.validate()) {
        axios({
          method: "POST",
          url: "/user/signup",
          data: {
            name: this.name,
            phone: this.phone,
            email: this.email,
            password: this.password,
            userType: this.userType,
            gender: this.gender,
            countryCode: this.formattedValue,
            otp:this.otp,
            guestId: localStorage.getItem("userId"),
          },
        }).then((response) => {
          if (response.data.status) {
            this.msg = "Successfully Registered";
            this.snackbar = true;
            store.commit("loginUser", response.data.userData);
            store.commit("userData", response.data.userData);
            localStorage.setItem("token", response.data.token);
            // localStorage.setItem("token", response.data.signingData.token);
            localStorage.removeItem("userId");
          } else {
            this.msg = response.data.msg;
            this.snackbar = true;
          }
        });
      }
    },
  },
};
</script>
<style scoped>
.align-right {
  text-align: right;
}
</style>